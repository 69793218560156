<template>
  <div class="settings">
    <app-redirect/>
  </div>
</template>

<script>
  import appRedirect from "../../../../components/appRedirect";
  export default {
    name: "src-pages-body-workstation-requests-offers-v1",
    components: {
      appRedirect
    },
  }
</script>
